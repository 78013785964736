import React, { useEffect, useRef, useState } from "react";
import "./searchbar.css";
import _axiosInstance from "../../config/api";
import Loader from "../loader/Loader";
import { Link } from "react-router-dom";

function Searchbar() {
  const [search, setSearchAI] = useState("");
  const [userstext, setUserInput] = useState([]);
  const resultContainerRef = useRef(null);
  const [loader, setLoader] = useState(false);

  const handleTextareaChange = (e) => {
    setSearchAI(e.target.value);
    e.target.style.height = "50px";
    e.target.style.height = e.target.scrollHeight + "px";
  };

  const handleSearchAI = async () => {
    const config = {
      headers: {
        Authorization: `Bearer ${JSON.parse(localStorage.getItem("360token"))}`,
        "content-type": "application/json",
      },
    };
    try {
      setLoader(true);
      setUserInput([
        ...userstext,
        {
          text: "",
          userInput: search,
        },
      ]);
      setSearchAI("");
      console.log("calling ai search");
      const responseData = await _axiosInstance.post(
        `/documents/aisearch`,
        { search: search },
        config
      );
      console.log("AI Search Response Data:", responseData.data);
      let updatedUserInputArray = [...userstext];
      updatedUserInputArray.push({
        text: responseData.data.data,
        userInput: search,
        link: responseData.data.link,
      });
      setUserInput(updatedUserInputArray);
      setLoader(false);
    } catch (error) {
      console.error(error);
      const errorMessage = error.response ? error.response.data.error : "Something went wrong, please try again later.";
      setUserInput([
        ...userstext,
        {
          text: <span style={{ color: 'red' }}>{errorMessage}</span>,
          userInput: search,
        },
      ]);
      setLoader(false);
    }
  };

  const handleKeyPress = async (e) => {
    if (e.key === "Enter") {
      console.log("test_", e.shiftKey);
      if (!e.shiftKey) {
        e.preventDefault();
        await handleSearchAI();
        e.target.style.height = "50px";
        setSearchAI("");
      }
    }
  };

  const copyData = (text) => {
    navigator.clipboard.writeText(text).then(() => {
      alert("Copied Done 🤙🤙");
    }).catch((err) => {
      console.error("Failed to copy: ", err);
    });
  };

  useEffect(() => {
    if (resultContainerRef.current) {
      resultContainerRef.current.scrollTop = resultContainerRef.current.scrollHeight;
    }
  }, [userstext]);

  const renderTextAsList = (text) => {
    // Split the text into sentences using full stops as delimiters
    const sentences = text.split('.').filter(sentence => sentence.trim().length > 0).map(sentence => sentence.trim() + '.');
  
    return (
      
        <ul>
          {sentences.map((sentence, index) => (
            <li key={index} className="listRespo">{sentence}</li>
          ))}
        </ul>
    );
  };
  

  return (
    <div>
      {loader && (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "60%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Loader />
        </div>
      )}
      <div className="result_container mt-5" ref={resultContainerRef}>
        <div className="searchResult">
          {userstext.map((data, index) => (
            <div key={index}>
              <div className="d-flex gap_10">
                <div>
                  <p className="icon_box icon_box_bl">You</p>
                </div>
                <div>
                  <p>{data.userInput}</p>
                </div>
              </div>
              {data?.text ? (
                <div className="d-flex gap_10">
                  <div>
                    <p className="icon_box icon_box_oran">Ask 360</p>
                  </div>
                  <div>
                    {data.text === "Please Contact Administration" ? (
                      <div>
                        <p style={{ color: "red" }}>{data.text}</p>
                      </div>
                    ) : (
                      <div>
                        {data.text.length > 500 ? (
                          renderTextAsList(data.text)
                        ) : (
                          <p>{data.text}</p>
                        )}
                        <div className="react_icons mt-3 mb-3 d-flex align-items-center gap_10">
                          <i className="fa-solid icon_hover fa-file" title="Copy" onClick={() => copyData(data.text)}></i>
                          {/* <i className="fa-solid icon_hover fa-thumbs-up" title="Like"></i>
                          <i className="fa-solid icon_hover fa-thumbs-down" title="Dislike"></i>
                          <i className="fa-solid icon_hover fa-rotate" title="Regenerate"></i> */}
                          <Link to={data.link} title={data.link} target="_blank" className="source_bard">
                            <i className="fa-solid fa-address-book source_icon"></i>
                            <p className="sourceText">Source</p>
                          </Link>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="search_conatiner">
        <textarea
          type="text"
          className="search_input shadow"
          placeholder="Search Here..."
          onChange={handleTextareaChange}
          value={search}
          onKeyPress={handleKeyPress}
        />
        <button className="btn_c" onClick={handleSearchAI}>
          <i className="fa-solid fa-paper-plane search_send_icon" title="Send Message"></i>
        </button>
      </div>
    </div>
  );
}

export default Searchbar;
