import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from "./components/dashboard/dashboard";
import "@fortawesome/fontawesome-free/css/all.min.css";
import Searchbar from "./components/searchbar/searchbar";
import Homepage from "./components/homepage/homepage";
import AddDocument from "./components/addDocument/addDocument";
import DocFolder from "./components/docFolders/docFolder";
import Login from "./components/login/login";
import PrivateRoute from "./config/PrivateRoute";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer, toast } from "react-toastify";
import Maintopbar from "./components/maintopbar/maintopbar";
import PrivateDashboard from "./components/dashboard/dashboard";
import ConfigSetup from "./components/setup/configSetup";
import AfterConfiguration from "./page/afterConfigurationPage/AfterConfigPage";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<PrivateDashboard />}>
          {/* <Route index element={<PrivateDashboard />} /> */}
          <Route index path="searchbar" element={<Searchbar />} />
          {/* <Route path="homepage" element={<Homepage />} /> */}
          <Route path="addDocument" element={<AddDocument />} />
          <Route path="docFolder" element={<DocFolder />} />
          <Route path="setup" element={<ConfigSetup />} />
        </Route>
        <Route path="/drive-config" element={<AfterConfiguration />} />
        <Route
          path="*"
          element={
            <>
              <h1>Not Found</h1>
            </>
          }
        />
      </Routes>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
