import "./dashboard.css";
import Maintopbar from "../maintopbar/maintopbar";
import React, { useState } from "react";
import { Outlet, Link, Navigate, useLocation } from "react-router-dom";
import {
  HomeOutlined,
  SearchOutlined,
  FolderOutlined,
  ArrowDropDown,
  Description,
  SettingsOutlined,
} from "@mui/icons-material";
import _axiosInstance from "../../config/api";
import { Modal } from "@mui/material";
import FolderIcon from '@mui/icons-material/Folder';
import { toast } from "react-toastify";

function PrivateDashboard() { 
  const [show, setShow] = useState(false);
  const token = localStorage.getItem("360token");
  const [activeButton, setActiveButton] = useState(0);
  const [activeFolderButton, setActiveFolderButton] = useState(0);
  const [openFolder, setOpenFolder] = useState(false);
  const [pramsData,setPramsData]=useState()
  const location = useLocation();
  const[role, setRole] = useState(null);
  const [permittedFolders, setPermittedFolders] = useState([]);
  const [newFolderModal, setNewFolderModal] = React.useState(false);
  const [folderName, setFolderName] = React.useState('');

  const toggleModal = () => setNewFolderModal(!newFolderModal)


  // const storedActiveButtonIndex = parseInt(
  //   localStorage.getItem("activeButtonIndex"),
  //   10
  // );
  // const storedActiveFolderButtonIndex = parseInt(
  //   localStorage.getItem("activeFolderButtonIndex"),
  //   10
  // );
  // React.useEffect(() => {
  //   setActiveButton(storedActiveButtonIndex || 0);
  //   setActiveFolderButton(storedActiveFolderButtonIndex || 0);
  // }, [activeFolderButton, activeButton]);

  const fetchRole = async () => {
    const response = await _axiosInstance.post('/users/role',{}, {
      headers:{
        Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("360token")
          )}`,
        "Content-Type": "application/json",
      }
    })

    return response.data?.role
  }

  const fetchPermittedFolders = async () => {
    const response = await _axiosInstance.post('/users/permitted_folders',{}, {
      headers:{
        Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("360token")
          )}`,
        "Content-Type": "application/json",
      }
    })
    return response.data?.permitted || [];
  }

  React.useEffect(() => {
    const fetchingRole = async () =>{
      try{
        const role = await fetchRole();
        setRole(role);
      }catch(error){
        console.log('Error in role request', error.message);
      }
    }

    const fetchingPermittedFolders = async () => {
      try{
        const permitted_folders = await fetchPermittedFolders();
        setPermittedFolders(permitted_folders)
      }catch(error){
        console.log('Error in role request', error.message);
      }
    }

    fetchingRole();
    fetchingPermittedFolders();
  }, [])

  const onClickhandler = (index) => {
    // localStorage.setItem("activeButtonIndex", index);
    setActiveButton(index);
    // localStorage.removeItem("activeFolderButtonIndex");
    setActiveFolderButton(0);
    if (index === role === 'admin' ? 4 : 3) {
      setOpenFolder(!openFolder);
    } else {
      setOpenFolder(false);
    }
  };

  const handleFolderName = async () => {
    try{
      const response_data = await _axiosInstance.post('/documents/newfolder', 
          {folderName: folderName},
          {
              headers:{
                  Authorization: `Bearer ${JSON.parse(
                      localStorage.getItem("360token")
                    )}`,
                  "Content-Type": "application/json",
              }
          }
      )
      console.log(response_data);
      toast.success(`${folderName} folder created successfully`);

      const updatedFolders = await fetchPermittedFolders();
      setPermittedFolders(updatedFolders);
    }catch(error){
      console.log(error.message)
      toast.error('Error in creating folder');
    }
  }


  const onClickFolderhandler = (val, index) => {
    // localStorage.setItem("activeFolderButtonIndex", index);
    setActiveFolderButton(index);
    const data = { key: val, page: 1 };
    setPramsData(data)
  };
  const navigation = [
    { name: "Search", path: "searchbar", icon: <SearchOutlined />, list: [] },
    // { name: "Dashboard", path: "homepage", icon: <HomeOutlined />, list: [] },
    {
      name: "Add Document",
      path: "addDocument",
      icon: <Description />,
      list: [],
    },
    role === 'admin' && {
      name: "Setup",
      path: "setup",
      icon: <SettingsOutlined />,
    },
    {
      name: "Folder",
      path: null,
      icon: <FolderOutlined />,
      dropDown: <ArrowDropDown />,
      list: [],
    },
  ].filter(Boolean);
  const navigationsubforlder = [
    { name: "All Documents", path: "docFolder", value: "ALL" },
    { name: "Case Study", path: "docFolder", value: "Case study" },
    { name: "SOW/WBS", path: "docFolder", value: "SOW" },
    { name: "RFP", path: "docFolder", value: "RFP" },
    { name: "DFD/Flow Diagram", path: "docFolder", value: "DFD/ Flow Diagram" },
    { name: "Misc. Documents", path: "docFolder", value: "Misc. Documents" },
  ];

  React.useEffect(() => {
    const currentPath = location.pathname.split("/")[2];
    console.log("current Path -->", currentPath);
    const navIndex = navigation.findIndex((nav) => nav.path === currentPath);
    console.log("index -->", navIndex);
    if(navIndex >= 0){
      setActiveButton(navIndex);
      setOpenFolder(navigation[navIndex].name === "Folder");
    }
  }, [location.pathname]);

  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }
  return (
    <div>
      {token ? (
        <div className="container_cust">
          {/*------------- for Web view sidebar -------------------------------*/}
          <div className={show ? "d_none_mob" : ""}>
            <div className="navigation for_desk">
              <ul className="ulclass one">
                {navigation.map((item, index) => (
                  <li className="" key={item.name}>
                    <Link
                      onClick={(e) => {
                        if (item.name === "Folder") {
                          e.preventDefault();
                        }
                        onClickhandler(index);
                      }}
                      key={item.name}
                      to={item.name === "Folder" ? null : item.path}
                      className={classNames(
                        activeButton === index ? "active" : ""
                      )}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <span title={item.name}>{item.icon}</span>
                      <span className="title" title={item.name}>{item.name}</span>
                      <span className="drop_icon text-end">
                        {item.dropDown}
                      </span>
                    </Link>
                  </li>
                ))}

                {openFolder && (
                  <div className="w-full flex gap-1 pl-6 flex-col scrollable-folder-list">
                    <ul>
                        <li>
                            <Link
                              className=""
                              onClick={toggleModal}
                            >
                              <span className="title small_fs">New Folder</span>
                          </Link>
                        </li>
                      {permittedFolders.map((item, index) => (
                        <li key={index}>
                          <Link
                            onClick={() =>
                              onClickFolderhandler(item, index)
                            }
                            key={item}
                            to="docFolder"
                            className={classNames(
                              activeFolderButton === index ? "active" : ""
                            )}
                          >
                            <span className="title small_fs" title={item}>{item}</span>
                       </Link>
                        </li>
                      ))}
                      {/* {navigationsubforlder.map((item, index) => (
                        <li key={index}>
                          <Link
                            onClick={() =>
                              onClickFolderhandler(item.value, index)
                            }
                            key={item.name}
                            to={item.path}
                            className={classNames(
                              activeFolderButton === index ? "active" : ""
                            )}
                          >
                            <span className="title small_fs" title={item.name}>{item.name}</span>
                       </Link>
                        </li>
                      ))} */}
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          </div>
          {/*------------- for mobile view sidebar -------------------------------*/}
          <div className={show ? "dblock" : "for_mob"}>
            <div className="navigation ">
              <i
                className="fa-solid fa-xmark text-white float-end pe-3 fs-4"
                onClick={() => setShow(!show)}
              ></i>
              <ul className="ulclass one">
                {navigation.map((item, index) => (
                  <li className="" key={item.name}>
                    <Link
                      onClick={(e) => {
                        if (item.name === "Folder") {
                          e.preventDefault();
                        }
                        if (item.name !== "Folder") {
                          setShow(!show);
                        }
                        onClickhandler(index);
                      }}
                      key={item.name}
                      to={item.name === "Folder" ? null : item.path}
                      state={{key:"Ravi Ojha"}}
                      aria-current={item.current ? "page" : undefined}
                    >
                      <span>{item.icon}</span>
                      <span className="title">{item.name}</span>
                      <span>{item.dropDown}</span>
                    </Link>
                  </li>
                ))}

                {openFolder && (
                  <div className="w-full flex gap-1 pl-6 flex-col submenues_display">
                    <ul>
                        <li>
                            <Link
                              className=""
                              onClick={toggleModal}
                            >
                              <span className="title small_fs">New Folder</span>
                          </Link>
                        </li>
                      {permittedFolders.map((item, index) => (
                        <li>
                          <Link
                            onClick={() =>
                              onClickFolderhandler(
                                item,
                                index,
                                setShow(!show)
                              )
                            }
                            key={item}
                            to="docFolder"
                            state={{key:item ,page:index}}
                            className={classNames(
                              activeFolderButton === index ? "active" : ""
                            )}
                          >
                            <span className="title small_fs">{item}</span>
                          </Link>
                        </li>
                      ))}
                      {/* {navigationsubforlder.map((item, index) => (
                        <li>
                          <Link
                            onClick={() =>
                              onClickFolderhandler(
                                item.value,
                                index,
                                setShow(!show)
                              )
                            }
                            key={item.name}
                            to={`${item.path}`}
                            state={{key:item?.value,page:index}}
                            className={classNames(
                              activeFolderButton === index ? "active" : ""
                            )}
                          >
                            <span className="title small_fs">{item.name}</span>
                          </Link>
                        </li>
                      ))} */}
                    </ul>
                  </div>
                )}
              </ul>
            </div>
          </div>
          <div className={show ? "p-4" : " main p-4"}>
            <div className="for_mob_none">
              <i class="fa-solid fa-bars" onClick={() => setShow(!show)}></i>
            </div>

            <Maintopbar />
            <Outlet context={[pramsData]} />
          </div>
        </div>
      ) : (
        <Navigate to="/" replace />
      )}

      {/* create new folder */}
        <Modal open={newFolderModal} onClose={toggleModal}>
          <div className="new_folder_modal">
              <h2>Create New Folder</h2>
              <div className="folder_input_container">
                <FolderIcon className="folder_icon"/>
                <input
                  className="folder_input"
                  placeholder="Enter Folder Name"
                  onChange={(e) => {
                    setFolderName(e.target.value);
                  }}
                >
                </input>
              </div>
              <button 
                className="save_folder_btn"
                onClick={() => {
                  handleFolderName()
                  toggleModal()
                }}
              >
                Save
              </button>
          </div>
        </Modal>
    </div>
  );
}
export default PrivateDashboard;
