import React from "react";
import "./AfterConfigPage.css"
import logo from "../../assets/logo.png"
import { useLocation } from "react-router-dom";
import gDriveSuccessIco from "../../assets/gDrive_sucessful.png";
import gDriveFailedIco from "../../assets/gDrive_failed.png";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';

function AfterConfiguration(){

    const location = useLocation();
    const [gDriveStatus, setGDriveStatus] = React.useState(false);

    
    
    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const status = queryParams.get('status');
        
        if(status === 'success'){
            setGDriveStatus(true);
        }else if(status === 'failed'){
            setGDriveStatus(false);
        }
        
    }, [location])
    
    React.useEffect(() => {
        setTimeout(() => {
            window.location.href = `${process.env.REACT_APP_UI_BASE_URL}/dashboard/setup?drive-config=${gDriveStatus}`
        }, 3000);
    })

    return(
        <div className="page_div">
            <nav className="page_nav">
                <img src={logo} alt="logo" className="org_logo" />
            </nav>
            <div className="page_body">
                <div className="config_msg_logo">
                    <img 
                        src={gDriveStatus ? gDriveSuccessIco : gDriveFailedIco}
                        alt="Configuration Message"
                        className="msg_ico"
                    />
                </div>
                <div className="config_msg">
                    <p className="fw-semibold fs-3 gDrive">Google Drive</p>
                    <p className="fw-bold fs-2 config_msg_inner">Integration
                        <span
                            className={gDriveStatus ? "success_msg": "failed_msg"}
                        >
                            {gDriveStatus ? "Successful" : "Failed"}
                        </span>
                    </p>
                </div>
            </div>
            <div className="page_back">
                <p className="back_msg fw-semibold">Redirecting to platform...</p>
            </div>
        </div>
    )
}

export default AfterConfiguration;