import { useEffect, useState } from "react"
import _axiosInstance from "../config/api";

function useUserSearch(query){
    const [isLoading, setIsLoading ] = useState(false);
    const [error, setError ] = useState(false);
    const [data, setData] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try{
                if (!query.trim()) {
                    setData([]); 
                    setIsLoading(false);
                    return;
                }
                setIsLoading(true);
                const response = await _axiosInstance(`/users/all-users?search=${query}`, {
                    headers:{
                        Authorization: `Bearer ${JSON.parse(localStorage.getItem("360token"))}`,
                        "Content-Type": "application/json"
                    }
                });
                setData(response?.data || [])
                setIsLoading(false);
            }catch(error){
                setData([])
                console.log("Error in searching the users");
                setError(true);
                setIsLoading(false);
            }
        }

        fetchData();
    }, [query])

    return {isLoading, error, data}
}

export default useUserSearch