import React from "react";
import { useLocation } from "react-router-dom";
import "./configSetup.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import _axiosInstance from "../../config/api";
import AddToDriveIcon from '@mui/icons-material/AddToDrive';
import FolderIcon from '@mui/icons-material/Folder';
import EditIcon from '@mui/icons-material/Edit';
import { List, ListItem, ListItemIcon, ListItemText, Checkbox, Modal, Button, InputAdornment } from "@mui/material";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    IconButton,
    Menu,
    MenuItem
} from "@mui/material";
import { Edit, Save, Delete, Cancel, RuleFolder, MoreVert, Search } from "@mui/icons-material";
import PaginationCode from "../PaginationCode/PaginationCode";
import useUserSearch from "../../hooks/useUserSearch";
import useDebounce from "../../hooks/useDebounce";


function ConfigSetup(){
    const location = useLocation();

    const [loading, setLoading] = React.useState(false);
    const [showDriveForm, setShowDriveForm] = React.useState(true);
    const [gptKeySaved, setGptKeySaved] = React.useState(true);
    const [fetchedFolders, setFetchedFolders] = React.useState([]);
    const [FolderModal, setFolderModal] = React.useState(false);
    const [userList, setUserList] = React.useState([]);
    const [editUserRow, setEditUserRow] = React.useState(null);
    const [userListTrigger, setUserListTrigger] = React.useState(false);
    const [selectedUserPermissions, setSelectedUserPermissions] = React.useState([]);
    const [pageCount, setPageCount] = React.useState(0);
    const [currentPage, setCurrentPage] = React.useState(1);
    const [selectedUserId, setSelectedUserId] = React.useState(null)
    const [originalPermissions, setOriginalPermissions] = React.useState([]);
    const [permission, setPermissions] = React.useState([]);
    const [openAiKey, setOpenAiKey] = React.useState("");
    const [openAiModel, setOpenAiModel] = React.useState("");
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [filterRole, setFilterRole] = React.useState(null);
    const [searchInput, setSearchInput] = React.useState('');

    const toggleModal = (userId = null) => {
        setSelectedUserId(userId)
        if (userId) {     
            const user = userList.find(user => user._id === userId);
            const userPermissions = user?.folderPermissions 
            ? user.folderPermissions.map(folder => folder.folderName) 
            : [];
            
            setSelectedUserPermissions([...userPermissions]);
            setOriginalPermissions([...userPermissions]);
        }else{
            setSelectedUserPermissions([...permission]);
            setOriginalPermissions([...permission]);
        } 

        setFolderModal(true);
    };

    const handleSavePermissions = () => {
        if (selectedUserId) {
            setUserList(prevList =>
                prevList.map(user =>
                    user._id === selectedUserId
                        ? { ...user, folderPermissions: selectedUserPermissions.map(name => ({ folderName: name })) }
                        : user
                )
            );
        } else {
            setPermissions([...selectedUserPermissions]); 
        }
        setFolderModal(false);
        toast.success("Permissions saved successfully");
    };

    const handleCloseModal = () => {
        setSelectedUserPermissions([...originalPermissions]); // Restore original selection
        setFolderModal(false);
    };


    const [userForms, setUserForms] = React.useState([
        {"name": "", "email": "", "role": ""},
    ])


    const [userFormsBulk, setUserFormsBulk] = React.useState([
        {"emails": "", "role": ""}
    ])

    const handleUserFormInput = (field, value) => {
        setUserForms(prevForms => ({
            ...prevForms,
            [field]: value
        }));

        if (field === "role") {
            setPermissions(value === "admin" ? fetchedFolders.map(folder => folder.name) : []);
        }
    }

    const handleUserFormBulkInput = (field, value) => {
        setUserFormsBulk(prevForms => ({
            ...prevForms,
            [field]: value
        }))

        if (field === "role") {
            setPermissions(value === "admin" ? fetchedFolders.map(folder => folder.name) : []);
        }
    }

    const handlePermissions = (folderName) => {
        console.log(folderName, '-----')
        setOriginalPermissions([...selectedUserPermissions]);
        setSelectedUserPermissions(prev => {
            const updatedPermissions = prev.includes(folderName)
                ? prev.filter(name => name !== folderName)  
                : [...prev, folderName];  
            return updatedPermissions;
        });
    } 

    React.useEffect(() => {
        const fetchAllFolders = async () => {
            try {
                const response = await _axiosInstance.get('/getFolders');
                
                console.log("Fetched Folders -->", response.data);
                setFetchedFolders(response.data?.filteredFolders || []);
                return response.data?.filteredFolders; 
            } catch (error) {
                console.error("Error fetching folders:", error);
                setFetchedFolders([]);
            }
        }

        fetchAllFolders();
    }, [])

    React.useEffect(() => {
        const fetchGptConfig = async () => {
            try{
                const response = await _axiosInstance.get("/config/get_gptConfig");

                setOpenAiKey(response?.data?.config[0]?.chatGpt_openAI_key);
                setOpenAiModel(response?.data?.config[0]?.chatGpt_Model);
            }catch(error){
                console.log("Error in fetching gpt config:", error);
            }
        }

        fetchGptConfig();
    }, [])

    const handleSelection = () => {
        setSelectedUserPermissions(prev => {

           const updatedPermissions = prev.length === fetchedFolders.length 
                ? []  
                : fetchedFolders.map(folder => folder.name)
            return updatedPermissions;
        })
        console.log(selectedUserPermissions);
    }

    const handleAddUsers = async () => {

        const data = {...userForms, permissions: selectedUserPermissions}

        if(!data.name || !data.email || !data.role){
            return toast.error("Name, Email and Role fields are required");
        }
        setLoading(true);


        try{
            const response_data = await _axiosInstance.post('/users/addMember', 
                data,
                {
                    headers:{
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("360token")
                          )}`,
                        "Content-Type": "application/json",
                    }
                }
            )
            setUserForms({
                name: "",
                email: "",
                role: ""
            });
            setUserListTrigger(prev => !prev);
            setLoading(false);
            toast.success("User Added Successfully");
        }catch(error){
            setLoading(false);
            toast.error("Error in Adding User");
            console.log(error.message)
        }
    }

    const handleAddMulUsers = async () => {

        const data = {...userFormsBulk, permissions: selectedUserPermissions}

        if(!data.emails|| !data.role){
            return toast.error("Email and Role fields are required");
        }
        setLoading(true);


        try{
            const response_data = await _axiosInstance.post('/users/add-bulk', 
                data,
                {
                    headers:{
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("360token")
                          )}`,
                        "Content-Type": "application/json",
                    }
                }
            )
            setUserFormsBulk({
                emails: "",
                role: ""
            });
            setUserListTrigger(prev => !prev);
            setLoading(false);
            toast.success("Users Added Successfully");
        }catch(error){
            setLoading(false);
            toast.error("Error in Adding Users");
            console.log(error.message)
        }
    }

    const googleToken = async () => {
        const response = await _axiosInstance.get("/config/ping-google-token")
        return response.data;
    }

    const fetchUserList = async (page) => {
        try{
            setLoading(true);
            const response = await _axiosInstance.get(`/users/users_in_slot?page=${page ? page : currentPage}`, 
                {
                    headers:{
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("360token")
                        )}`,
                        "Content-Type": "application/json",
                    }
                }
            )
            setPageCount(response?.data?.pagination?.totalPages);
            setLoading(false);
            return response.data?.users;
        }catch(error){
            console.log(error);
            setLoading(false);
        }
    }

    React.useEffect(() => {
        const fetchGoogleToken = async () => {
            try{
                const token = await googleToken();
                console.log("token --->", token)
                if(token.success){
                    setShowDriveForm(false);
                }else{
                    setShowDriveForm(true);
                }
            }catch(error){
                console.log(error);
                setShowDriveForm(true);
            }
        }
        fetchGoogleToken();
    }, [showDriveForm])


    const handleCreateDriveConfig = async (e) => {
        e.preventDefault();
        
        setLoading(true);
        try{
            window.location.href = `${_axiosInstance.defaults.baseURL}/google/auth/google`
            setLoading(false);
        }catch(error){
            setLoading(false);
            console.log(error.message);
        }
    }

    const handleCreateGptConfig = async (e) => {
        e.preventDefault();

        const chatGpt_openAI_key = openAiKey
        const chatGpt_Model = openAiModel

        if(chatGpt_openAI_key === ""){
            toast.error("Please Enter Open AI Key")
        }else if(chatGpt_Model === ""){
            toast.error("Please Enter Gpt Model")
        }else {
            setLoading(true);

            try{
                const responseData = await _axiosInstance.post(
                    "/config/gpt",
                    {chatGpt_openAI_key, chatGpt_Model}
                )

                setLoading(false);
                toast.success("Gpt Configuration Saved Successfully");
                setGptKeySaved(true);
            }catch(error){
                setLoading(false);
                console.log(error.message);
            }
        }
    }

    React.useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        const config = queryParams.get('drive-config');

        if(config === 'true'){
            setShowDriveForm(false);
        }else if(config === 'false'){
            setShowDriveForm(true);
        }

    }, [location])

    const handleEditUserRow = (id) => {
        setEditUserRow(id);
    }

    const handleSaveUserList = async (id) => {
        setLoading(true);
        const data = userList.find(user => user._id === id);
        const updatedData = {...data, folderPermissions: selectedUserPermissions};
        try{
            const response = await _axiosInstance.put(
                `/users/updateUser?user_Id=${id}`, updatedData,
                {
                    headers:{
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("360token")
                        )}`,
                        "Content-Type": "application/json",
                    }
                }
            )
            setEditUserRow(null);
            setUserListTrigger(prev => !prev);
            setLoading(false);
            toast.success('User Updated successfully');
        }catch(error){
            console.log(error);
            toast.error('Error in updating the user');
        }
    };

    const handleDeleteUserList = async (id) => {
        try{
            setLoading(true);
            const response = await _axiosInstance.delete(
                `/users/deleteUser?user_Id=${id}`,
                {
                    headers:{
                        Authorization: `Bearer ${JSON.parse(
                            localStorage.getItem("360token")
                        )}`,
                        "Content-Type": "application/json",
                    }
                }
            ) 
            setUserList(userList.filter((user) => user._id !== id));
            setLoading(false);
            toast.success('User deleted successfully');
            setUserListTrigger(prev => !prev);
        }catch(error){
            console.log("Error in deleting the user", error);
            toast.error('Error in deleting the user');
        }
    };

    const handleChangeInUserList = (e, id) => {
        const { name, value } = e.target;
        setUserList(
            userList.map((user) => (user._id === id ? { ...user, [name]: value } : user))
        );
      };
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
        setCurrentPage(pageNumber)
    }

    React.useEffect(() => {
        const fetchAllUsers = async () => {
            const users = await fetchUserList();
            if(users && users.length > 0){
                setUserList(users);
            }else{
                setUserList([]);
            }
        }

        fetchAllUsers();
    }, [userListTrigger, currentPage]);

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = (role = null) => {
        setAnchorEl(null);
        setFilterRole(role)
    };

    const debounceSearch = useDebounce(searchInput, 1000)
    const {isLoading, error, data: searchedUsers} = useUserSearch(debounceSearch);

    const displayedUsers = searchInput ? searchedUsers : userList.filter(user => !filterRole || user.role === filterRole);

    return(
        <>
            <div>
                {
                    loading?<React.Fragment>
                    <div className="loaders">
                        <div className="loader">
                        </div>
                    </div>
                    </React.Fragment>:null
                }

                <p className="text-center fw-bold fs-4 mt-3">Setup</p>
                <form className="form_container shadow px_5_cu py_5_cu mt-4 rounded-4">
                    <p className="text-center fw-semibold fs-5">Google Drive Configuration</p>
                    <div class="d-flex justify-content-center">
                        <button
                            onClick={(e) => handleCreateDriveConfig(e)}
                            className="btn fw-semibold text-uppercase gDrive-btn"
                            type="button"
                            disabled={!showDriveForm}
                        >
                            <AddToDriveIcon className="gDrive-icon"/>
                            {showDriveForm ? "Cofigure Your Google Drive" : "Drive Already Configured" }
                        </button>
                    </div>
                </form>
                <form className="form_container shadow px_5_cu py_5_cu mt-5 mb-4 rounded-4">
                    <p className="text-center fw-semibold fs-5">Open AI Configuration</p>
                    <form>
                        <div className="gpt_form form-control mb-4">
                            <input
                                type="text"
                                className="gpt_input"
                                placeholder="Enter Open AI Key"
                                name="chatGpt_openAI_key"
                                autoComplete="off"
                                onChange={(e) => setOpenAiKey(e.target.value)}
                                disabled={gptKeySaved}
                                defaultValue={openAiKey || ""}
                            /> 
                            <div className="edit-class">
                                <EditIcon 
                                    className="edit-ico"
                                    onClick={() => setGptKeySaved(gptKeySaved && !setGptKeySaved)}
                                />
                            </div>
                        
                        </div>
                        <select
                            name="chatGpt_Model"
                            className="select_model mb-4"
                            onChange={(e) => setOpenAiModel(e.target.value)}
                            value={openAiModel || ""}
                        >
                                <option value="">Select OpenAI Model</option>
                                <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
                                <option value="gpt-4-32k">GPT-4o</option>
                                <option value="gpt-4-32k-mini">GPT-4o mini</option>
                                <option value="gpt-4">GPT-4</option>
                                <option value="gpt-4-turbo">GPT-4 Tubro</option>
                        </select>
                        <div class="d-grid">
                            <button
                                onClick={(e) => handleCreateGptConfig(e)}
                                class="btn btn_light text-white"
                                type="button"
                            >
                                Save
                            </button>
                        </div>
                    </form>
                </form>
                <div className="form_container shadow px_5_cu py_5_cu mt-5 mb-4 rounded-4">
                    <p className="text-center fw-semibold fs-5">Add Users</p>
                    <div className="user_forms">
                        <div className="user_forms_fn">
                            <input
                                className="user_forms_input"
                                placeholder="Name"
                                value={userForms.name}
                                onChange={(e) => handleUserFormInput("name", e.target.value)}
                            />

                            <input
                                className="user_forms_input"
                                placeholder="Email"
                                value={userForms.email}
                                onChange={(e) => handleUserFormInput("email", e.target.value)}
                            />

                            <select
                                className="user_forms_input"
                                value={userForms.role}
                                defaultValue=""
                                onChange={(e) => handleUserFormInput("role", e.target.value)}
                            >
                                <option value="" disabled>Select Role</option>
                                <option value="admin">admin</option>
                                <option value="member">member</option>
                            </select>

                            <button onClick={() => toggleModal(null)} className="permission_btn">
                                Permissions
                            </button>
                        </div>
                        <div className="user_utils_btn">
                            <button className="add_btn" onClick={handleAddUsers}>
                                Add
                            </button>
                        </div>
                    </div>
                    <div className="user_forms">
                        <div className="user_forms_fn">
                            <input
                                className="user_forms_input"
                                style={{width: "375px"}}
                                placeholder="Enter Emails Seperated By ','"
                                value={userFormsBulk.emails}
                                onChange={(e) => handleUserFormBulkInput("emails", e.target.value)}
                            />

                            <select
                                className="user_forms_input"
                                value={userFormsBulk.role}
                                defaultValue=""
                                onChange={(e) => handleUserFormBulkInput("role", e.target.value)}
                            >
                                <option value="" disabled>Select Role</option>
                                <option value="admin">admin</option>
                                <option value="member">member</option>
                            </select>

                            <button onClick={() => toggleModal(null)} className="permission_btn">
                                Permissions
                            </button>
                        </div>
                        <div className="user_utils_btn">
                            <button className="add_btn" onClick={handleAddMulUsers}>
                                Add Multiple users
                            </button>
                        </div>
                    </div>

                    {/* <button onClick={addUserForm} className="plus-btn" >
                        <AddIcon />
                    </button> */}

                    <Paper 
                        elevation={3} 
                        sx={{
                            display: "flex",
                            alignItems: "center",
                            justifySelf: "center",
                            width: "80%",
                            maxWidth: 500,
                            borderRadius: 3,
                            p: "4px 10px",
                            boxShadow: "0px 3px 10px rgba(0,0,0,0.1)",
                            transition: "0.3s",
                            "&:hover": { boxShadow: "0px 5px 15px rgba(0,0,0,0.2)" }
                        }}
                    >
                        <TextField
                            fullWidth
                            variant="standard"
                            placeholder="Search by name, email, role"
                            value={searchInput}
                            onChange={(e) => setSearchInput(e.target.value)}
                            autoComplete="off"
                            InputProps={{
                                disableUnderline: true,
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <Search sx={{ color: "gray" }} />
                                    </InputAdornment>
                                )
                            }}
                            sx={{
                                flex: 1,
                                fontSize: "16px",
                                p: 1,
                                "& input": { color: "#333" },
                            }}
                        />
                    </Paper>

                    <div style={{ padding: 20 }}>
                        <TableContainer component={Paper}>

                        <Table>
                            <TableHead sx={{backgroundColor: '#2A2185'}}>
                                <TableRow>
                                    {["ID", "Name", "Email", "Role", "Folder Permission", "Actions"].map((header) => (
                                        <TableCell key={header} 
                                            sx={{ color: "#ffffff", fontWeight: "bold"}}
                                        >
                                            {header}
                                        </TableCell>
                                    ))}

                                    <TableCell sx={{ color: "#ffffff", fontWeight: "bold", textAlign: "right", position: 'relative', padding: 0 }}>
                                        <IconButton 
                                            onClick={handleMenuOpen} 
                                            sx={{ color: "#ffffff", padding: 0,  position: 'absolute', right: 10, top: '50%', transform: 'translateY(-50%)' }}
                                        >
                                            <MoreVert />
                                        </IconButton>
                                        <Menu
                                            anchorEl={anchorEl}
                                            open={Boolean(anchorEl)}
                                            onClose={() => handleMenuClose()}
                                        >
                                            <MenuItem onClick={() => handleMenuClose("admin")}>Admin</MenuItem>
                                            <MenuItem onClick={() => handleMenuClose("member")}>Member</MenuItem>
                                            <MenuItem onClick={() => handleMenuClose(null)}>Clear Filter</MenuItem>
                                        </Menu>
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {error ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center" style={{ color: "red" }}>
                                            Error fetching users.
                                        </TableCell>
                                    </TableRow>
                                ) : isLoading ? (
                                    <TableRow>
                                        <TableCell colSpan={6} align="center">Loading users...</TableCell>
                                    </TableRow>
                                ): 
                                    displayedUsers.length > 0 ? (
                                        displayedUsers
                                        .map((user) => (
                                            <TableRow key={user._id} sx={{'&:nth-of-type(even)': {backgroundColor: '#edf6f9'}}}>
                                                <TableCell>{user._id}</TableCell>

                                                <TableCell>
                                                    {editUserRow === user._id ? (
                                                        <TextField
                                                            name="name"
                                                            value={user.name}
                                                            onChange={(e) => handleChangeInUserList(e, user._id)}
                                                            size="small"
                                                        />
                                                    ) : (
                                                        user.name
                                                    )}
                                                </TableCell>

                                                <TableCell>{user.email}</TableCell>

                                                <TableCell>
                                                    {editUserRow === user._id ? (
                                                        <select
                                                            name="role"
                                                            value={user.role}
                                                            onChange={(e) => handleChangeInUserList(e, user._id)}
                                                            className="form-control"
                                                        >
                                                            <option value="admin">Admin</option>
                                                            <option value="member">Member</option>
                                                        </select>
                                                    ) : (
                                                        user.role
                                                    )}
                                                </TableCell>

                                                <TableCell
                                                    className="permissionBtn_table"
                                                    onClick={() => {
                                                        if (editUserRow === user._id) {
                                                            toggleModal(user._id);
                                                        }
                                                    }}
                                                    style={{ opacity: editUserRow === user._id ? 1 : 0.3, cursor: editUserRow === user._id ? 'pointer' : 'default' }}
                                                >
                                                    <RuleFolder fontSize="large" />
                                                </TableCell>

                                                <TableCell>
                                                    {editUserRow === user._id ? (
                                                        <>
                                                            <IconButton onClick={() => handleSaveUserList(user._id)}>
                                                                <Save color="primary" />
                                                            </IconButton>
                                                            <IconButton onClick={() => setEditUserRow(null)}> 
                                                                <Cancel color="error" />
                                                            </IconButton>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <IconButton onClick={() => handleEditUserRow(user._id)}>
                                                                <Edit color="primary" />
                                                            </IconButton>
                                                            <IconButton onClick={() => handleDeleteUserList(user._id)}>
                                                                <Delete color="error" />
                                                            </IconButton>
                                                        </>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        ))
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={6} align="center">No users found</TableCell>
                                        </TableRow>
                                    )
                                }
                            </TableBody>
                        </Table>

                            <PaginationCode
                                currentPage={currentPage}
                                totalPages={pageCount}
                                onPageChange={handlePageChange}
                            />
                        </TableContainer>
                    </div>
                </div>

                {/* set permission of folders modal */}
                <Modal open={FolderModal} onClose={() => setFolderModal(false)}>
                    <div className="modal_inner">
                        <p className="modal_header">
                            Select Folders
                            <Button
                                size="small"
                                variant="outlined"
                                className="all_check_btn"
                                onClick={handleSelection}
                            >
                                {selectedUserPermissions.length === fetchedFolders.length ? "Unselect All" : "Select All"}
                            </Button>
                        </p>
                        <List className="modal_center">
                            { fetchedFolders.length > 0 && fetchedFolders.map((folder, index) => (
                                <ListItem
                                    key={`${index}-${folder.name}`}
                                    sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        gap: 2,
                                    }}
                                >
                                    <ListItemIcon>{<FolderIcon />}</ListItemIcon>
                                    <ListItemText
                                        primary={folder.name}
                                    />
                                    <Checkbox
                                        checked={selectedUserPermissions.includes(folder.name)}
                                        onChange={() => handlePermissions(folder.name)}
                                    />
                                </ListItem>
                                ))
                            }
                        </List>
                        <div className="modal_actions">
                            <Button variant="contained" color="primary" onClick={handleSavePermissions}>
                                Save
                            </Button>
                            <Button variant="contained" color="error" onClick={handleCloseModal}>
                                Close
                            </Button>
                        </div>
                    </div>
                </Modal>
            </div>
        
        </>
    )
}

export default ConfigSetup;