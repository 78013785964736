
import React from 'react';
import { Pagination } from 'react-bootstrap';

const PaginationCode = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (pageNumber) => {
    console.log(pageNumber)
    onPageChange(pageNumber);
  };

  // const validTotalPages = Math.max(1, Math.ceil(totalPages || 1));

  return (
    <Pagination className='mt-3 justify-content-end me-2'>
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
      />
      
        <Pagination.Item
          key={currentPage}
          active={currentPage}
          onClick={() => handlePageChange(currentPage + 1)}
        >
          {currentPage}
        </Pagination.Item>
      
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
      />
    </Pagination>
  );
};

export default PaginationCode;

