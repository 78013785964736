import React from "react";
import "./addDocument.css";
import "react-toastify/dist/ReactToastify.css";
import { toast } from "react-toastify";
import { useNavigate,useLocation } from "react-router-dom";
import { jwtDecode } from "jwt-decode";
import _axiosInstance from "../../config/api";

function AddDocument() {
  const navigate = useNavigate();
  const [userDetails, setUserDetails] = React.useState(null);
  const [formdata, setFormdata] = React.useState({
    name: "",
    documenttype: "",
    file: "",
    uploadedby: "",
    description: "",
    driveUrl: "",
  });
  const [loading, setLoading] = React.useState(false);
  const acceptedFileTypes = ".pdf, .doc, .ppt, .xlsx";
  const location =useLocation()
  const [permittedFolders, setPermittedFolders] = React.useState([]);

  const fetchPermittedFolders = async () => {
    const response = await _axiosInstance.post('/users/permitted_folders',{}, {
      headers:{
        Authorization: `Bearer ${JSON.parse(
            localStorage.getItem("360token")
          )}`,
        "Content-Type": "application/json",
      }
    })
    return response.data?.permitted || [];
  }

  React.useEffect(() => {
    const user = jwtDecode(`${JSON.parse(localStorage.getItem("360token"))}`);
    setUserDetails(user);
    setFormdata({
        ...formdata,
        ['uploadedby']: user?.id.Username,
        // [event.target.name[3]]: event.target.file,
      });
  }, []);

  React.useEffect(() => {
      const fetchingPermittedFolders = async () => {
        try{
          const permitted_folders = await fetchPermittedFolders();
          setPermittedFolders(permitted_folders)
        }catch(error){
          console.log('Error in role request', error.message);
        }
      }

      fetchingPermittedFolders();
    }, [])

  const onChangehandler = (event) => {
    console.log(event.target.value);
    setFormdata({
      ...formdata,
      [event.target.name]: event.target.value,
      // [event.target.name[3]]: event.target.file,
    });
  };

  const onChangefilehandler = (event) => {
    setFormdata({ ...formdata, [event.target.name]: event.target.files[0] });
  };
  const maxSize = 100 * 1024 * 1024;
  const handelAddDocument = async (e) => {
    e.preventDefault();
    console.log(formdata);
    const { name, documenttype, file, uploadedby, description, driveUrl } =
      formdata;
    if (name === "") {
      toast.error("Please Enter Name");
    } else if (documenttype === "") {
      toast.error("Please Enter documenttype");
    } else if (file === "") {
      toast.error("Please Select File ");
    } 
    else if (file.size > maxSize) {
      toast.error("Please Select File Upto 100 MB");
    }
    else if (uploadedby === "") {
      toast.error("Please Enter uploadedby");
    } else if (description === "") {
      toast.error("Please Enter description");
    } else {
      setLoading(true);
      const FormDataDOC = new FormData();
      FormDataDOC.append("name", formdata.name);
      FormDataDOC.append("documenttype", formdata.documenttype);
      FormDataDOC.append("file", formdata.file ? formdata.file : "");
      FormDataDOC.append("uploadedby", formdata.uploadedby);
      FormDataDOC.append("description", formdata.description);
      FormDataDOC.append(
        "driveUrl",
        formdata.driveUrl ? formdata.driveUrl : ""
      );

      try {
        const config = {
          headers: {
            Authorization: `Bearer ${JSON.parse(
              localStorage.getItem("360token")
            )}`,
            "content-type": "application/json",
            "content-type": "multipart/form-data",
          },
        };
        const responseData = await _axiosInstance.post(
          "/documents/upload",
          FormDataDOC,
          config
        );

        setLoading(false);

        toast.success("Document Uploaded Successfully");
        setTimeout(() => {
          navigate("/dashboard/docFolder",{ state: true });
        }, 2500);
      } catch (error) {
        setLoading(false);
        console.log(error.message);
      }
    }
  };
  return (
    <>
      <div>
        {
          loading?<React.Fragment>
            <div className="loaders">
              <div className="loader">
              </div>
            </div>
          </React.Fragment>:null
        }
        
      <p className="text-center fw-bold fs-4 mt-3">Add New Document</p>
      <form className="form_container shadow px_5_cu py_5_cu mt-4 rounded-4">
        <input
          type="text"
          className="form-control mb-4"
          placeholder="Enter Document Name"
          name="name"
          onChange={onChangehandler}
          autoComplete="off"

        />
        <select
          className="form-select mb-4"
          aria-label="Default select example"
          name="documenttype"
          onChange={onChangehandler}
        >
          <option value="" selected disabled hidden>
            Document Type
          </option>
          {permittedFolders.length > 0 ?
              permittedFolders.map((folder) => (
                <option value={folder}>{folder}</option>
              )) :
                <option value="">No Folder Found</option>
          }
          {/* <option value="Case study">Case study</option>
          <option value="SOW">SOW/WBS</option>
          <option value="RFP">RFP</option>
          <option value="DFD/ Flow Diagram">DFD/ Flow Diagram</option>
          <option value="Misc. Documents">Misc. Documents</option> */}
        </select>
        <input
          onChange={onChangefilehandler}
          accept={acceptedFileTypes}
          type="file"
          name='file'
          className="form-control mb-4"
        />
        <input
          type="text"
          className="form-control mb-4"
          placeholder="Uploaded By"
          name="uploadedby"
          value={userDetails?.id && userDetails?.id.Username}
          onChange={onChangehandler}
          autoComplete="off"
        />

        <textarea
          className="form-control mb-4"
          rows="2"
          name="description"
          placeholder="Enter Your Description"
          onChange={onChangehandler}
        ></textarea>
        <div class="d-grid">
          <button
            onClick={(e) => handelAddDocument(e)}
            class="btn btn_light text-white"
            type="button"
          >
            Upload
          </button>
        </div>
      </form>
    </div>
    
    </>
    
  );
}
export default AddDocument;
