import axios from "axios";

console.log("process.env.REACT_APP_API_BASE_URL", process.env.REACT_APP_API_BASE_URL);
const  _axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_BASE_URL,
  timeout: 100000,
});
console.log("_axiosInstance baseURL -->", _axiosInstance.defaults.baseURL);

export default _axiosInstance;
